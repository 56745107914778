<template>
  <el-form
    v-if="form"
    ref="FormTwo"
    class="medium-form"
    size="medium"
    label-width="135px"
    :rules="rules"
    :model="form"
  >
    <!--    label-width="40%"-->
    <el-form-item label="场地开放预定：" prop="status">
      <el-switch v-model="form.status" :active-value="1" :inactive-value="0">
      </el-switch>
    </el-form-item>
    <!--
"reservable_position_ids": [],
        // "reservable_user_ids": []
        "reservable_users": []
-->
    <el-form-item label="场地使用权限：">
      <div class="flex-col">
        <el-radio-group
          v-model="form.can_whole_user_reserve"
          class="flex-align-center"
        >
          <el-radio :label="1">全员可用</el-radio>
          <el-radio :label="2">部分职务可用</el-radio>
          <el-radio :label="3">指定用户可用</el-radio>
        </el-radio-group>
        <el-radio-group
          v-show="form.can_whole_user_reserve === 1"
          v-model="form.can_visitor_reserve"
          class="flex-align-center"
        >
          <!--
  form.can_visitor_reserve
  -->
          <el-radio :label="1">所有用户可用（包括游客）</el-radio>
          <!--
   v-model="form.can_whole_user_reserve"
  -->
          <el-radio :label="0">会员可用</el-radio>
        </el-radio-group>
      </div>
    </el-form-item>

    <el-form-item v-show="form.can_whole_user_reserve === 3" label="选择用户：">
      <!--
       style="margin-bottom: 7px;"
      -->
      <selected-users
        v-model="form.reservable_users"
        @select="showMemberSelector = true"
      />
    </el-form-item>

    <!--    <el-form-->
    <!--      label-width="100px"-->
    <!--      size="small"-->
    <!--      class="form small-form"-->
    <!--      label-position="left"-->
    <!--      :rules="rules"-->
    <!--      :model="form"-->
    <!--    >-->
    <el-form-item
      v-if="form.can_whole_user_reserve === 2"
      label="选择架构职务："
      prop="reservable_position_ids"
    >
      <el-cascader
        style="width: 295px"
        v-if="positionOptions"
        v-model="form.reservable_position_ids"
        :options="positionOptions"
        :props="props"
        collapse-tags
        clearable
      >
      </el-cascader>
    </el-form-item>
    <!--    </el-form>-->

    <el-form-item label="关联活动：" prop="can_bind_activity">
      <el-switch
        v-model="form.can_bind_activity"
        :active-value="1"
        :inactive-value="0"
      >
      </el-switch>
    </el-form-item>

    <!--
    "min_reserve_time_period": 0,
        "max_reserve_time_period": 0,
        "reserve_time_scope": 0,
    -->
    <el-form-item label="单次可预订最小时段：" prop="min_reserve_time_period">
      <el-select v-model="form.min_reserve_time_period">
        <el-option
          v-for="p in [
            {
              id: 0,
              name: '请选择',
            },
            // {
            //   id: 15,
            //   name: '15分钟',
            // },
            {
              id: 30,
              name: '30分钟',
            },
            {
              id: 60,
              name: '1小时',
            },
          ]"
          :key="p.id"
          :label="p.name"
          :value="p.id"
          :disabled="p.id === 0"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="单次可预订最长时段：" prop="max_reserve_time_period">
      <el-select v-model="form.max_reserve_time_period">
        <el-option
          v-for="p in [
            {
              id: 30,
              name: '30分钟',
            },
            {
              id: 60,
              name: '1小时',
            },
            {
              id: 120,
              name: '2小时',
            },
            {
              id: 180,
              name: '3小时',
            },
            {
              id: 360,
              name: '6小时',
            },
            {
              id: 720,
              name: '12小时',
            },
            {
              id: 0,
              name: '无限制',
            },
          ]"
          :key="p.id"
          :label="p.name"
          :value="p.id"
        ></el-option>
      </el-select>
    </el-form-item>
<!--    <el-form-item label="可预订范围：" prop="reserve_time_scope">-->
<!--      <el-select v-model="form.reserve_time_scope">-->
<!--        <el-option-->
<!--          v-for="p in [-->
<!--            {-->
<!--              id: '0',-->
<!--              name: '请选择',-->
<!--            },-->
<!--            {-->
<!--              id: '1day',-->
<!--              name: '一天',-->
<!--            },-->
<!--            {-->
<!--              id: '3day',-->
<!--              name: '3天',-->
<!--            },-->
<!--            {-->
<!--              id: '7day',-->
<!--              name: '1周',-->
<!--            },-->
<!--            {-->
<!--              id: '14day',-->
<!--              name: '2周',-->
<!--            },-->
<!--            {-->
<!--              id: '21day',-->
<!--              name: '3周',-->
<!--            },-->
<!--            {-->
<!--              id: '1month',-->
<!--              name: '1月',-->
<!--            },-->
<!--            {-->
<!--              id: '2month',-->
<!--              name: '2月',-->
<!--            },-->
<!--            {-->
<!--              id: '3month',-->
<!--              name: '3月',-->
<!--            },-->
<!--            {-->
<!--              id: '6month',-->
<!--              name: '半年内',-->
<!--            },-->
<!--            {-->
<!--              id: '12month',-->
<!--              name: '一年内',-->
<!--            },-->
<!--          ]"-->
<!--          :key="p.id"-->
<!--          :label="p.name"-->
<!--          :value="p.id"-->
<!--          :disabled="p.id == 0"-->
<!--        ></el-option>-->
<!--      </el-select>-->
<!--    </el-form-item>-->

    <!--
      style="max-width: calc(670px + 135px)"
-->
    <el-form-item
      label="开放时间："
      prop="reserve_times"
      style="max-width: calc(933px + 135px + 57px)"
    >
      <template v-for="(g, gI) in form.reserve_times">
        <date-group
          v-model="form.reserve_times[gI]"
          :key="g.id"
          :index="gI"
          @remove="form.reserve_times.splice(gI, 1)"
        ></date-group>
      </template>
      <div>
        <el-button type="primary" @click="addDateGroup()"
          >新增开放时间</el-button
        >
      </div>
    </el-form-item>

    <el-form-item class="unique" label="特殊日期段：">
      <div class="unique-times">
        <div
          v-for="(exc, excI) in form.special_times"
          :key="exc.id"
          class="unique-time flex-align-center"
        >
          <el-date-picker
            v-model="exc.dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始"
            end-placeholder="结束"
            value-format="timestamp"
            :picker-options="{
              disabledDate: (time) => {
                let flag = false
                form.special_times.forEach((s) => {
                  if (s.dateRange && s.dateRange.length) {
                    if (
                      time.getTime() > s.dateRange[0] &&
                      time.getTime() < s.dateRange[1]
                    ) {
                      flag = true
                    }
                  }
                })
                return flag
              },
            }"
          ></el-date-picker>
          <el-button
            @click="form.special_times.splice(excI, 1)"
            type="text"
            class="remove"
            style="margin-left: 0"
            >删除</el-button
          >
        </div>
      </div>
      <p class="info">
        <i class="el-icon-warning"></i
        >特殊日期段的日子将不会在页面可预约日期显示
      </p>
      <div style="margin-top: 16px">
        <el-button type="primary" @click="addUniqueDate()"
          >新增特殊日期</el-button
        >
      </div>
    </el-form-item>

    <!--    <el-calendar v-model="current">-->
    <!--      &lt;!&ndash; Use 2.5 slot syntax. If you use Vue 2.6, please use new slot syntax&ndash;&gt;-->
    <!--      <template-->
    <!--              slot="dateCell"-->
    <!--              slot-scope="{date, data}">-->
    <!--        {{date.getTime() * 0.001}}#{{data}}-->
    <!--&lt;!&ndash;-->
    <!--data.isSelected || -->
    <!--&ndash;&gt;-->
    <!--        <p :class="selectedDates.includes(parseCurrent(date)) ? 'is-selected' : ''">-->
    <!--          {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : ''}}-->
    <!--        </p>-->
    <!--      </template>-->
    <!--    </el-calendar>-->

    <!-- 选择会员 -->
    <member-selector
      v-model="showMemberSelector"
      :disable-members="disabledUsers"
      :hash="true"
      @changeSelect="handleAddUsers"
    />
  </el-form>
</template>

<script>
import weektimeData from '../../data/weektime_data.js'

import SelectedUsers from '../../components/add/SelectedUsers'
import MemberSelector from '@/modules/common/components/MemberSelector'
import { positionLevelForVenue } from '../../api'
import { randomString } from '../../../../base/utils/tool'
import DateGroup from './date-group'

export default {
  components: { DateGroup, MemberSelector, SelectedUsers },
  inject: ['getForm'],
  data() {
    return {
      current: new Date(),
      // 都存时间戳
      enableDates: [], // 场地可预约日期
      selectedDates: [], // 勾选的日期

      showMemberSelector: false,

      // 职务数组
      positionOptions: [],
      // 职务数组props
      props: {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'position',
        emitPath: false,
        expandTrigger: 'hover',
      },
      rules: {
        reservable_position_ids: [
          { required: true, message: '请选择职务', trigger: 'change' },
          {
            validator: (r, value, callback) => {
              if (value && value.length) {
                callback()
              } else {
                callback(new Error('请选择职务'))
              }
            },
            message: '请选择职务',
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    form() {
      return this.getForm()
    },
    tenantVersion() {
      return this.$store.getters.tenant?.version
    },
    disabledUsers() {
      return this.form.reservable_users
    },
  },
  watch: {
    current(newValue) {
      const T = this.parseCurrent(newValue)
      if (this.selectedDates.includes(T)) {
        this.selectedDates.splice(this.selectedDates.indexOf(T), 1)
      } else {
        this.selectedDates.push(T)
      }
    },
    /**
     "can_whole_user_reserve": 1,
     "can_visitor_reserve": 1,

     "reservable_position_ids": [],
     // "reservable_user_ids": []
     "reservable_users": []
     */
    'form.can_whole_user_reserve'(val) {
      this.handleScopeReverse()
    },
    'form.can_visitor_reserve'(val) {
      this.handleScopeReverse()
    },
    // form: {
    //   handler() {
    //     this.handleScope()
    //   },
    //   immediate: true,
    // },
  },
  created() {
    this.getPositionLevel()
  },
  methods: {
    addUniqueDate() {
      const newItem = {
        start_date: 0,
        end_date: 0,
        dateRange: '',
        id: randomString(8),
      }
      this.form.special_times.push(newItem)
    },
    addDateGroup() {
      const data = JSON.parse(JSON.stringify(weektimeData))
      const data2 = JSON.parse(JSON.stringify(weektimeData.slice(0, 1)))
      const newItem = {
        id: randomString(8),
        mode: 'week', // week or day

        start_date: '', // 时间戳
        end_date: '', // 时间戳

        // 辅助 start_date 和 end_date
        dateRange: '',

        mult_timeRange: [],
        mult_timeRange2: [],

        weektimeData: data, // 稍后根据接口返回构造
        daytimeData: data2,

        inclusionsEnable: 0,
        inclusions: [], // 特定日期
      }
      console.log(newItem);
      this.form.reserve_times.push(newItem)
    },
    // 获取职务
    getPositionLevel() {
      positionLevelForVenue({
        hashid_enable: 1,
        is_list_tree: 0,
        is_show_all: 0,
      }).then((res) => {
        this.positionOptions = res.data
      })
    },
    handleAddUsers(e) {
      this.form.reservable_users.push(...e)
    },
    parseCurrent(current) {
      return current.getTime() * 0.001
    },
    /**
     * @deprecated
     * 根据接口返回的数据回显
     */
    handleScope() {
      if (!this.form) return
      const {
        can_whole_user_reserve,
        can_visitor_reserve,
        reservable_position_ids,
        reservable_users,
      } = this.form
      if (can_visitor_reserve) {
        this.scope = 0
        this.childScope = 0
      } else if (can_whole_user_reserve) {
        this.scope = 0
        this.childScope = 1
      } else if (reservable_position_ids?.length) {
        this.scope = 1
      } else if (reservable_users?.length) {
        this.scope = 2
      }
    },
    /**
     * 修改真正要提交的数据
     */
    handleScopeReverse() {
      const { can_whole_user_reserve: scope, can_visitor_reserve: childScope } =
        this.form

      if (scope === 1) {
        if (childScope === 1) {
          this.form.reservable_position_ids = []
          this.form.reservable_users = []
        } else {
          this.form.reservable_position_ids = []
          this.form.reservable_users = []
        }
      } else if (scope === 2) {
        this.form.reservable_users = []
      } else {
        this.form.reservable_position_ids = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.medium-form .el-calendar-table .el-calendar-day {
  height: fit-content;
}

.is-selected {
  color: $primary;
}

.flex-col {
  .el-radio-group {
    height: 40px;
  }
}

.unique-times {
  margin-bottom: 16px;
}

.remove {
  color: $danger;
}

.unique {
  .info {
    margin-top: -4px !important;
  }
}

.unique-times {
  .unique-time {
    .el-range-editor {
      margin-right: 16px;
    }
  }

  .unique-time + .unique-time {
    margin-top: 16px;
  }

  &.info {
    margin-top: 8px;
  }
}
</style>
