<template>
  <div class="open-group">
    <div class="open-group-title flex-between">
      {{ `时间段${characters[index] || index + 1}` }}
      <el-button @click="$emit('remove')" type="text" class="remove"
        >删除</el-button
      >
    </div>
    <el-form class="medium-form" label-position="left" label-width="120px">
      <el-form-item label="场地开放日期：">
        <el-date-picker
          v-model="value.dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始"
          end-placeholder="结束"
          value-format="timestamp"
          :picker-options="pickerOptions"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="开放时间类型：">
        <el-radio-group
          v-model="value.mode"
          class="flex-align-center"
          style="height: 36px"
        >
          <el-radio label="day">每天</el-radio>
          <el-radio label="week">每周</el-radio>
        </el-radio-group>
        <div
          v-if="value.mode === 'week'"
          style="
            margin-top: 20px;
            overflow-x: auto;
            max-width: calc(716px + 135px);
          "
        >
          <drag-weektime
            ref="weekTime"
            v-model="mult_timeRange"
            :data="value.weektimeData"
            @on-clear="clearWeektime"
          ></drag-weektime>
        </div>
        <div
          v-if="value.mode === 'day'"
          style="
            margin-top: 20px;
            overflow-x: auto;
            max-width: calc(716px + 135px);
          "
        >
          <drag-weektime
            ref="weekTime"
            v-model="mult_timeRange2"
            :data="value.daytimeData"
            @on-clear="clearWeektime"
          ></drag-weektime>
        </div>
      </el-form-item>
      <!-- 特定开放时间 -->
      <el-form-item label="特定时间类型：">
        <!--
         :value="!!value.inclusions.length"
          @change="onSwitchExclusions('when_is_empty')"
        -->
        <el-switch
          v-model="value.inclusionsEnable"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
        <p class="info">
          <i class="el-icon-warning"></i
          >
          在开放时间段内，可单独设置某天的开放时间。如跟每天、每周的开放时间有重叠，优先以特殊时间段为准
        </p>
        <div
          v-if="value.inclusions.length"
          v-for="(exc, excI) in value.inclusions"
          :key="exc.id"
          class="exclude-box"
        >
          <div class="exclude-item"></div>
          <el-form
            label-position="left"
            label-width="83px"
            inline
            class="flex-col"
          >
            <el-form-item label="选择日期：" class="">
              <div class="flex">
                <el-date-picker
                  v-model="exc.dateRange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始"
                  end-placeholder="结束"
                  value-format="timestamp"
                  :picker-options="pickerOptionsInclude"
                />
                <el-button
                  @click="value.inclusions.splice(excI, 1)"
                  type="text"
                  class="remove"
                  style="margin-left: 16px"
                  >删除</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="选择时段：" class="flex-1">
              <div
                class="time-ranges flex-align-center"
                v-for="(td, tdI) in exc.timeData"
                :key="td.id"
              >
                <div class="flex time-select-range">
                  <!--
  parseToTime(parseToStamp(value.inclusions[excI - 1].end_time) - 30 * 60)
  -->
                  <el-time-select
                    style="width: 100%"
                    placeholder="开始"
                    v-model="td.start_time"
                    :picker-options="{
                      start: '06:00',
                      step: '00:30',
                      end: '24:00',
                      minTime: tdI > 0 ? exc.timeData[tdI - 1].end_time : 0,
                    }"
                  >
                  </el-time-select>
                  <span style="padding: 0 10px">至</span>
                  <el-time-select
                    style="width: 100%"
                    placeholder="结束"
                    v-model="td.end_time"
                    :picker-options="{
                      start: '06:00',
                      step: '00:30',
                      end: '24:00',
                      minTime: td.start_time,
                    }"
                  >
                  </el-time-select>
                </div>
                <el-button
                  v-if="exc.timeData.length > 1"
                  @click="exc.timeData.splice(tdI, 1)"
                  type="text"
                  class="remove"
                  style="margin-left: 16px"
                  >删除</el-button
                >
              </div>

              <!--              <el-time-picker-->
              <!--                v-for="exc in value.inclusions"-->
              <!--                :key="exc.id"-->
              <!--                is-range-->
              <!--                v-model="exc.timeRange"-->
              <!--                range-separator="至"-->
              <!--                start-placeholder="开始"-->
              <!--                end-placeholder="结束"-->
              <!--                format="HH:mm"-->
              <!--              >-->
              <!--              </el-time-picker>-->
            </el-form-item>
            <el-form-item label=" " style="margin-top: 0">
              <div class="flex-col">
                <el-button
                  @click="handleAddTimeData(excI)"
                  style="margin-top: 4px"
                  type="text"
                  >+ 添加时间段</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
        <add-button
          v-if="value.inclusionsEnable"
          :height="48"
          style="margin-top: 20px"
          @click="onSwitchExclusions()"
          >添加特定时间段</add-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import DragWeektime from './drag-weektime'
import { copyText, randomString } from '../../../../base/utils/tool'
import { parseToStamp, parseToTime } from '../../js/time-tool'
import AddButton from '../../../../base/components/Base/AddButton'

function splicing(list) {
  let same
  let i = -1
  const len = list.length
  const arr = []

  if (!len) return
  while (++i < len) {
    const item = list[i]
    if (item.check) {
      if (item.check !== Boolean(same)) {
        arr.push(...['、', item.begin, '~', item.end])
      } else if (arr.length) {
        arr.pop()
        arr.push(item.end)
      }
    }
    same = Boolean(item.check)
  }
  arr.shift()
  return arr.join('')
}

export default {
  name: 'date-group',
  components: {
    AddButton,
    DragWeektime,
  },
  props: {
    value: Object,
    index: Number,
  },
  data() {
    return {
      characters: require('../../data/ch-sort'),
      // 时间选择范围设置
      pickerOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time)
        },
      },
      pickerOptionsInclude: {
        disabledDate: (time) => {
          return this.selectableInclude(time)
        },
      },
    }
  },
  computed: {
    mult_timeRange() {
      return this.value[
        true || this.value.mode === 'week' ? 'weektimeData' : 'daytimeData'
      ].map((item) => {
        return {
          id: item.row,
          week: item.value,
          value: splicing(item.child),
        }
      })
    },
    mult_timeRange2() {
      return this.value[
        false && this.value.mode === 'week' ? 'weektimeData' : 'daytimeData'
      ].map((item) => {
        return {
          id: item.row,
          week: item.value,
          value: splicing(item.child),
        }
      })
    },
  },
  watch: {
    mult_timeRange: {
      handler(val) {
        // console.log(val)

        const arr = []

        val
          .filter((el) => !!el.value)
          .forEach((w) => {
            const timeRangeArr = w.value.split('、')

            // 处理成接口保存的格式
            timeRangeArr.forEach((ww) => {
              arr.push({
                // 星期一 ~ 星期日
                day: [
                  '星期日',
                  '星期一',
                  '星期二',
                  '星期三',
                  '星期四',
                  '星期五',
                  '星期六',
                ].indexOf(w.week),

                begin: parseToStamp(ww.split('~').map((el) => el.trim())[0]),
                end:
                  parseToStamp(ww.split('~').map((el) => el.trim())[1]) ||
                  86400,
              })
            })
          })

        this.value.mult_timeRange = arr
      },
      deep: true,
      immediate: true,
    },
    mult_timeRange2: {
      handler(val) {
        // console.log(val)

        const arr = []

        val
          .filter((el) => !!el.value)
          .forEach((w) => {
            const timeRangeArr = w.value.split('、')

            // 处理成接口保存的格式
            timeRangeArr.forEach((ww) => {
              arr.push({
                day: '每天',
                begin: parseToStamp(ww.split('~').map((el) => el.trim())[0]),
                end:
                  parseToStamp(ww.split('~').map((el) => el.trim())[1]) ||
                  86400,
              })
            })
          })

        this.value.mult_timeRange2 = arr
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    parseToTime,
    parseToStamp,
    onSwitchExclusions(ifState = '') {
      if (!this.value) return
      if (!this.value.inclusions) return
      // if (ifState === 'when_is_empty' && this.value.inclusions.length) return

      let copyRange
      // if (false && this.value.inclusions.length) {
      //   copyRange =
      //     this.value.inclusions[this.value.inclusions.length - 1].dateRange
      // }
      // todo:
      this.value.inclusions.push({
        dateRange: copyRange || '',
        // timeRange: '',

        start_date: 0,
        end_date: 0,
        // start_time: 0,
        // end_time: 0,
        id: randomString(8),
        timeData: [
          {
            start_time: '',
            end_time: '',
            begin: 0,
            end: 0,
          },
        ], // {begin: 0, end: 0}[]
      })
    },
    // 设置时间选择范围
    selectableDate(time) {
      return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
    },
    selectableInclude(time) {
      if (!this.value.dateRange) return false

      let flag = false

      this.value.inclusions.forEach((inc) => {
        if (inc.dateRange?.length) {
          if (
            time.getTime() >= inc.dateRange[0] &&
            time.getTime() <= inc.dateRange[1]
          ) {
            flag = true
          }
        }
      })

      if (!flag)
        return !(
          time.getTime() >= this.value.dateRange[0] &&
          time.getTime() <= this.value.dateRange[1]
        )
      return flag
    },
    clearWeektime() {
      this.value[
        this.value.mode === 'week' ? 'weektimeData' : 'daytimeData'
      ].forEach((item) => {
        item.child.forEach((t) => {
          this.$set(t, 'check', false)
        })
      })
    },
    copyJson() {
      copyText(JSON.stringify(this.value))
    },
    getWeekTime() {
      return this.$refs.weekTime?.selectValue
    },
    handleAddTimeData(index) {
      this.value.inclusions[index].timeData.push({
        start_time: '',
        end_time: '',
        begin: 0,
        end: 0,
        id: randomString(8),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* 开放时间 */
.open-group {
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  background: #ffffff;
  margin-bottom: 16px;
  overflow: hidden;

  .open-group-title {
    height: 48px;
    background: #f5f7fa;
    padding: 13px 18px;

    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }

  .el-form.medium-form {
    /*padding: 24px 18px;*/
    margin: 24px 18px;

    .el-form-item + .el-form-item {
      margin-top: 24px;
    }
  }

  .remove {
    color: $danger;
  }

  .exclude-box {
    border-radius: 4px;
    background: #f5f7fa;
    margin-top: 16px;
    padding: 16px;

    .time-select-range {
      width: 350px;
    }

    .el-range-editor + .el-range-editor,
    .el-button + .el-button,
    .time-ranges + .time-ranges {
      margin-top: 16px;
    }

    ::v-deep(.el-form-item) {
      .el-form-item__content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
