<template>
  <el-form
    v-if="form"
    ref="FormThree"
    class="medium-form"
    size="medium"
    label-width="115px"
    :rules="rules"
    :model="form"
  >
<!--    label-width="40%"-->
    <el-form-item label="审批：" prop="approval_enable">
      <el-switch
        v-model="form.approval_enable"
        :active-value="1"
        :inactive-value="0"
      >
      </el-switch>
    </el-form-item>

    <el-form-item label="申请表单：" prop="approval_tmpl_id">
      <div class="flex-col">
        <el-radio-group
          v-model="form.use_default_approval_tmpl"
          class="flex-align-center"
        >
          <el-radio :label="1">默认</el-radio>
          <el-radio :label="0">模板</el-radio>
        </el-radio-group>
        <el-form
          v-if="form.use_default_approval_tmpl === 0"
          size="mini"
          label-width="155px"
        >
          <el-form-item label="选择模板：">
            <el-button style="margin-right: 10px" type="primary" @click="isShowSelectTemplate = true"
              >选择</el-button
            >
            <span v-if="form.tmpl && form.tmpl.id">已选：{{ form.tmpl? form.tmpl.name : '' }}</span>
          </el-form-item>
        </el-form>
      </div>
    </el-form-item>

    <el-form-item label="申请限制：" prop="reserve_limit">
      <el-radio-group v-model="form.reserve_limit" class="flex-align-center">
        <el-radio :label="0">无限制</el-radio>
        <el-radio :label="1">周期限制</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item v-if="form.reserve_cycle" v-show="form.reserve_limit" label="次数显示：">
      <!--
    "reserve_cycle": {
        "time_value": 2,
        "time": "month",
        "apply_limit": 2
    }
-->
      <div class="form-inline">
        <el-input
          v-model="form.reserve_cycle.time_value"
          type="number"
          :min="1"
        ></el-input>
        <el-select v-model="form.reserve_cycle.time">
          <el-option
            v-for="p in [
              {
                id: 'day',
                name: '天',
              },

              {
                id: 'month',
                name: '月',
              },
            ]"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          ></el-option>
        </el-select>
        <span>为一周期，周期内可申请</span>
        <el-input
          v-model="form.reserve_cycle.apply_limit"
          type="number"
          :min="1"
        >
          <template #append>
            <el-button>次</el-button>
          </template>
        </el-input>
      </div>
      <p class="info"><i class="el-icon-info"></i>如两个月可申请两次</p>
    </el-form-item>

    <venue-template-selector v-model="isShowSelectTemplate" @changeSelect="onSelectTemplate"></venue-template-selector>
  </el-form>
</template>

<script>
import VenueTemplateSelector from './VenueTemplateSelector'
export default {
  components: {VenueTemplateSelector},
  inject: ['getForm'],
  data() {
    return {
      rules: {},
      isShowSelectTemplate: false
    }
  },
  computed: {
    form() {
      return this.getForm()
    },
    tenantVersion() {
      return this.$store.getters.tenant?.version
    },
    templateId: {
      get() {
        return this.form.approval_tmpl_id ? 1 : 0
      },
      set(val) {
        if (!val) {
          this.form.approval_tmpl_id = ''
        }
      },
    },
  },
  watch: {},
  created() {},
  methods: {
    onSelectTemplate(e) {
      // console.log(e) // array
      this.form.approval_tmpl_id = e[0].id
      this.form.tmpl = e[0]
    }
  },
}
</script>

<style lang="scss" scoped>
.flex-col {
  .el-radio-group {
    height: 40px;
  }
}

.form-inline > * {
  margin-right: 10px;
}

.form-inline > :not(.el-input-group) {
  width: 100px;
}

.form-inline .el-input-group {
  width: 150px;
}
</style>
