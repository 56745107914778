<template>
  <div>
    <div class="add-subjects">
      <div class="subject-list" v-show="myValue">
        <div class="single-subject">
          <div class="item">
            <div class="content">
              <div class="label">
                <el-button
                  class="company-address el-input el-input--medium"
                  :class="[myValue.address ? '' : 'placeholder']"
                  @click="handleAddressSelect()"
                >
<!--                  <div class="flex-between">-->

<!--                  icon="el-icon-location-outline"-->
                  <template v-if="myValue.name">{{ myValue.name }}(</template>
                  {{ myValue.address || '请选择' }}
                  <template v-if="myValue.name">)</template>
                  <div class="el-input__suffix">
                    <div class="el-input__suffix-inner">
                  <i class="el-icon-location-outline el-input__icon"></i>

                    </div>
                  </div>
<!--                  </div>-->
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddressSelector
      v-model="openAddressSelector"
      @change="handleChangeAddress"
      :detailForm="currentForm"
    />
  </div>
</template>

<script>
import AddressSelector from '../../../common/components/AddressSelector.vue'
export default {
  name: 'venue-address',
  components: { AddressSelector },
  model: {
    prop: 'value',
    event: 'changeValue',
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      myValue: {},
      // 打开地址弹窗
      openAddressSelector: false,
      // 当前操作的form
      currentForm: {
        lat: '',
        lng: '',
        address: '',
        remark: '',
        start_time: -1,
        end_time: -1,
        activityTime: [],
        addressName: '',
        name: ''
      },
      rules: {
        // address: [
        //   { required: true, message: '请选择地址', trigger: 'change' },
        // ],
      },
    }
  },
  watch: {
    value: {
      handler(val) {
        // console.log(val);
        this.myValue = val || {
          lat: '',
          lng: '',
          address: '',
          name: '',
        }
      },
      immediate: true,
      // deep: true,
    },
  },
  computed: {},
  created() {
    // console.log(this.myValue);
  },
  methods: {
    // 打开地址选择器
    handleAddressSelect() {
      console.log(this.myValue)
      // 修改当前操作的form
      this.currentForm = this.myValue
      console.log(this.currentForm)
      // 打开地址弹窗
      this.openAddressSelector = true
    },
    // 地址改变
    handleChangeAddress(value) {
      // this.myValue[this.currentI] = value;
      this.myValue = Object.assign(this.myValue, {...value, name: value.address_name})
    },
  },
}
</script>

<style lang="scss" scoped>
.add-subjects {
  .subject-list {
    display: flex;
    flex-direction: column;
    .single-subject {
      display: flex;
      justify-content: space-between;
      .item {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 5px;
        color: #909399;
        .subject-btn {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .content {

          .label {
            display: flex;
            color: #606266;
            span {
              width: 90px;
              text-align: right;
              margin-right: 8px;
            }
            .el-input {
              flex: 1;
            }
            .textarea {
              flex: 1;
              max-width: none;
            }
            ::v-deep .el-textarea__inner {
              height: 80px;
            }
          }
        }
      }
      .flex {
        display: flex;
        padding-top: 10px;
        span {
          width: 15px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
}
.el-form {
  width: 100%;
  margin: 5px 0;
}

.company-address {
  width: 100%;
  text-align: left;
  padding-left: 8px;
  padding-right: 30px;
  @include nowrap();
  /*position: relative;*/

  &.placeholder {
    color: #C0C4CC;
  }
}
</style>
