<template>
  <draggable class="user-list flex" v-model="myValue" animation="500" draggable=".user-item" filter=".filter">
    <div v-for="(user, userIndex) in myValue" :key="user.id" class="user-item" :title="user && user.title_name || ''">
      <i class="el-icon-error cursor-pointer" @click="myValue.splice(userIndex, 1)" />
      <list-image :src="fixImageUrl(user && user.avatar || defaultAvatar, 150)" :preview="false"
                  :width="60" :height="60" fit="cover" :border-radius="4" />
      <span class="name">{{user && user.nickname || ''}}</span>
    </div>
    <add-button class="filter" text="" :width="60" :height="60" @click="$emit('select')" />
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import ListImage from "@/base/components/List/ListImage";
import AddButton from "@/base/components/Base/AddButton";

export default {
  name: "SelectedUsers",
  components: {AddButton, ListImage, draggable},
  props: {
    value: {
      type: Array,
      default: () => {}
    },
  },
  data() {
    return {
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png"
    }
  },
  computed: {
    myValue: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .user-list {
    flex-wrap: wrap;
  }

  .user-item {
    position: relative;
    width: 60px;
    margin-right: 25px;
    margin-bottom: 25px;
    cursor: move;

    $icon-s: 18px;

    .el-icon-error {
      position: absolute;
      right: -$icon-s * 0.5;
      top: -$icon-s * 0.5;
      font-size: $icon-s;
      z-index: 1;
      opacity: .7;
    }

    .name {
      text-align: center;
      display: block;
      line-height: 1.5;
      margin: 10px 0 0;
      word-break: break-all;
    }
  }
</style>
