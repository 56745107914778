<template>
  <el-dialog title="选择模板" :visible.sync="openDialog" width="58%"
    :show-close="false" class="dialog-vertical" @close="cancel">
    <div class="head-filter" :style="'width: 50%'">
      <el-input v-model="filterForm.keyword" placeholder="请输入模板名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list" style="margin-top: 35px">
      <el-table :data="memberList" style="width: 100%" :height="350" ref="multipleTable"
        :highlight-current-row="!selectType" @row-click="handleCurrentChange" @selection-change="handleSelectionChange"
        v-loading="loading" v-loadmore="LoadMore">
        <!-- 多选 -->
        <el-table-column ref="selection" fixed type="selection" width="70" :selectable="selectable" v-if="selectType"
          :key="Math.random()"></el-table-column>
        <!-- 单选 -->
        <el-table-column fixed width="70" v-if="!selectType" :key="Math.random()">
          <template slot-scope="scope">
            <el-radio :label="scope.row" v-model="currentRow"
              :disabled="disableMembers.length ? (disableMembers.findIndex(item => item.user_id === scope.row.user_id) !== -1) : (selectedMembers.findIndex((item) => item.user_id === scope.row.user_id) !== -1)">
              {{ "&nbsp" }}</el-radio>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :width="th.width || ''" :key="index">
            <template slot-scope="{row}">
              <img v-if="th.prop === 'icon' && row[th.prop]" style="width: 60px;" :src="row[th.prop]" />
              <p v-else style="white-space: pre-wrap;">{{ row[th.prop] | placeholder }}</p>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getTemplateList } from "../../api/template-list";
import debounce from "lodash/debounce";

export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 已选择会员数据
    Members: {
      type: Array,
      default: () => [],
    },
    // 限制选择的会员数据
    disableMembers: {
      type: Array,
      default: () => [],
    },
    // 0:单选/1:多选模式
    selectType: {
      type: Number,
      default: 0,
    },
    //点击确定后返回数据形式:适用多选(total: 已选中和弹窗选中会员总和/check：弹窗当前选中会员)
    selectedType: {
      type: String,
      default: "total",
    },
    // 当前选中索引
    currentIndex: {
      type: Number,
      default: 0,
    },
    // 选择数量限制
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    const thead = [
      { label: "ID", prop: "id" },
      { label: "图标", prop: "icon" },
      { label: "模板名称", prop: "name" },
      { label: "创建时间", prop: "create_time" },
    ];
    return {
      loading: false, //加载
      thead,
      memberList: [],
      // 筛选对象
      filterForm: {
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      },
      getable: true, //是否可以请求列表
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      currentRow: "", // 单选时选中的数据项
      OrganizationList: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取会员列表
    getSearchMemberList: debounce(function (pageData) {
      if (this.getable) {
        this.loading = true;
        getTemplateList({...pageData, status: 1})
          .then((res) => {
            const { data } = res;
            if (pageData.page === 1) {
              this.memberList = data.data;
            } else {
              this.memberList = [...this.memberList, ...data.data];
            }
            if (data.data.length < this.filterForm.page_size) {
              this.getable = false;
            }
            this.filterForm.page++;
            this.loading = false;
          })
          .catch((err) => { });
      }
    }, 200),
    // 判断是否可选
    selectable(row, index) {
      let disableMembers = this.disableMembers.length ? this.disableMembers : this.selectedMembers;
      if (disableMembers.findIndex((item) => item.user_id === row.user_id) !== -1) {
        return false;
      } else {
        return true;
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm };
      fliterData.organization_id = fliterData.organization_id === "" ? -1 : fliterData.organization_id;
      fliterData.position_id = fliterData.position_id === "" ? -1 : fliterData.position_id;
      fliterData.page = key ? fliterData.page : 1;
      this.getable = true;
      this.getSearchMemberList(fliterData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        if (selection.length > this.limit) {
          this.selectArr = selection.slice(0, this.limit);
          const leftArr = selection.slice(this.limit);
          this.$message.error(`最多可选择 ${this.limit} 个会员`);
          leftArr.forEach((el) => {
            this.$refs.multipleTable.toggleRowSelection(el, false);
          });
        } else {
          this.selectArr = [...selection];
        }
      }
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      if (!this.selectType) {
        let disabled
        if (this.disableMembers.length) {
          disabled = this.disableMembers.findIndex(item => item.user_id === val.user_id) !== -1
        } else {
          disabled = this.selectedMembers.findIndex((item) => item.user_id === val.user_id) !== -1
        }

        if (!disabled) this.currentRow = val;
      } else {
        if (this.selectedMembers.findIndex((item) => item.user_id === val.user_id) !== -1) {
          return;
        } else {
          this.memberList.forEach((element) => {
            if (val.user_id === element.user_id) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(element);
              });
            }
          });
        }
      }
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        this.handleFilter(this.filterForm, "Load");
      }
    },
    // 确定
    submit() {
      if (this.selectType) {
        if (!this.selectArr.length) {
          this.$message.info("请选择数据项");
        } else {
          const arr = this.selectedType === "total" ? [...this.selectedMembers, ...this.selectArr] : [...this.selectArr];
          this.selectedMembers = arr;
          this.$refs.multipleTable.clearSelection();
          this.selectArr = [];
          this.openDialog = false;
        }
      } else {
        if (!this.currentRow) {
          this.$message.info("请选择数据项");
        } else {
          this.selectedMembers.splice(this.currentIndex, 1, this.currentRow);
          this.$emit("changeSelect", this.selectedMembers);
          this.openDialog = false;
        }
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        const data = { ...this.filterForm, page: 1 };
        this.getSearchMemberList(data);
      } else {
        this.$refs.multipleTable.clearSelection();
        this.selectArr = [];
      }
    },
    memberList: {
      deep: true,
      handler(val) {
        console.log(this.memberList, "this.memberList");
        this.memberList.forEach((el) => {
          if (this.selectArr.findIndex((elem) => elem.user_id === el.user_id) !== -1 && this.$refs.multipleTable) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true);
            });
          }
        });
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    selectedMembers: {
      get() {
        return this.Members;
      },
      set(val) {
        this.$emit("changeSelect", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  // width: 80%;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  justify-content: flex-end;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
