<template>
  <div class="step-box">
    <!-- 步骤条 -->
    <el-steps :active="stepStatus" align-center>
      <el-step v-for="(item, i) in onLine.filter(o => o.show)" :key="i">
        <template #title>
          <div class="step-title" @click="changeStatus(i)">{{ item.name }}</div>
        </template>
        <template #icon>
          <div class="step-icon" @click="changeStatus(i)"></div>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>
<script>


export default {
  name: "VenueStep",
  model: {
    prop: "status",
    event: "changeStatus",
  },
  props: {
    status: {
      type: Number,
      default: 1,
    },
    // 操作类型， add新增，edit编辑
    type: {
      type: String,
      default: "add",
    },
    // 是否禁用步骤条点击
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {  },
  created() {
    // 根据编辑状态，显示对应步骤名称
  },
  computed: {
    // 步骤条状态 初始状态是 0
    stepStatus: {
      get() {
        return this.$props.status - 1;
      },
      set(val) {
        this.$emit("changeStatus", val + 1);
      },
    },
  },
  data() {
    return {
      // 线上
      onLine: [
        { name: "添加场地", show: !this.$route.params.id },
        { name: "场地信息", show: !!this.$route.params.id },
        { name: "场地设置", show: true },
        { name: "预定设置", show: true },
      ],
    };
  },
  methods: {
    next() {
      this.stepStatus++;
    },
    preNext() {
      this.stepStatus--;
    },
    changeStatus(current) {
      // 新增不许切换步骤条, 判断是否禁用
      if (this.$props.type === "add" || this.$props.isDisabled) {
        return;
      }
      // this.stepStatus = current;
      // 加一才是真实的步骤  + 1
      this.$emit("clickStatus", current + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
// 步骤框样式调整
.step-icon {
  width: 100%;
  height:100%;
  cursor: pointer;
}
.step-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
::v-deep .is-process {
  color: #3576ff !important;
  border-color: #3576ff !important;
}
::v-deep .el-step__head.is-finish {
  .el-step__line {
    background-color: #3576ff !important;
  }
  
}
::v-deep .el-step__icon-inner {
    display: none !important;
  }

  ::v-deep .el-steps.el-steps--horizontal {
    .el-step__icon {
      width: 16px !important;
      height: 16px !important;
    }
    .el-step.is-horizontal .el-step__line {
      top:7px;
    }
    .el-step__head.is-finish {
      .el-step__icon {
        background-color: #3576ff;
    }
    }
  }
</style>
