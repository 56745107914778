<template>
  <div class="c-weektime">
    <div class="c-schedue"></div>
    <div
      :class="{ 'c-schedue': true, 'c-schedue-notransi': mode }"
      :style="styleValue"
    ></div>

    <div class="c-weektime-table-wrapper">
      <table border="0" cellpadding="0" cellspacing="0" :class="{ 'c-min-table': colspan < 2 }" class="c-weektime-table">
        <thead class="c-weektime-head">
          <tr>
            <th v-if="data.length > 1" rowspan="8" class="week-td">星期/时间</th>
            <th :colspan="12 * colspan * 0.5">
              <i class="el-icon-time"> </i> 06:00 - 12:00
            </th>
            <th :colspan="12 * colspan">
              <i class="el-icon-time"> </i> 12:00 - 24:00
            </th>
          </tr>
          <tr>
            <td v-for="t in theadArr" :key="t" :colspan="colspan">{{ t }}</td>
          </tr>
        </thead>
        <tbody class="c-weektime-body">
          <tr v-for="t in data" :key="t.row">
            <td v-if="data.length > 1">{{ t.value }}</td>
            <td
              v-for="n in t.child"
              :key="`${n.row}-${n.col}`"
              :data-week="n.row"
              :data-time="n.col"
              :class="selectClasses(n)"
              @mouseenter="cellEnter(n)"
              @mousedown="cellDown(n)"
              @mouseup="cellUp(n)"
              class="weektime-atom-item"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>

    <table style="width: 100%">
      <tbody>
        <tr>
          <td :colspan="colspan * (12 + 6 + 1)" class="c-weektime-preview">
            <div class="g-clearfix c-weektime-con">
              <span class="g-pull-left">{{
                selectState ? '已选时间段' : '可拖动鼠标选择时间段'
              }}</span>
              <a @click.prevent="$emit('on-clear')" class="g-pull-right"
                >全部清空</a
              >
            </div>
            <!--
              style="height: 200px"
-->
            <div v-if="selectState" class="c-weektime-time">
              <div
                v-for="t in selectValue.filter((s) => !!s.value)"
                :key="t.id"
                class="preview-time flex-between"
              >
                <p v-if="t.value">
                  <span v-if="data.length > 1" class="g-tip-text">{{ t.week }}：</span>
                  <span>{{ t.value }}</span>
                </p>

                <el-button
                  @click="clearWeekDay(t.week)"
                  type="text"
                  class="danger"
                  >删除</el-button
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const createArr = (len) => {
  return Array.from(Array(len)).map((ret, id) => id + 6)
}
export default {
  name: 'DragWeektime',
  props: {
    value: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    colspan: {
      type: Number,
      default() {
        return 2
      },
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      mode: 0,
      row: 0,
      col: 0,
      theadArr: [],
      border: 1
    }
  },
  computed: {
    styleValue() {
      return {
        // - (this.col - 1 > 0 ? this.col - 1 : 0) * 10
        //  - (this.row - 1 > 0 ? this.row - 1 : 0) * 10
        width: `${this.width + this.border}px`,
        height: `${this.height + this.border}px`,
        //  + 1.5 * this.border
        //  + 1.5 * this.border
        left: `${this.left + 1}px`,
        top: `${this.top + 1}px`,
      }
    },
    selectValue() {
      return this.value
    },
    selectState() {
      return this.value.some((ret) => ret.value)
    },
    selectClasses() {
      return (n) => (n.check ? 'ui-selected' : '')
    },
  },
  created() {
    console.log(this.data,'data');
    this.theadArr = createArr(24 - 6)
  },
  methods: {
    clearWeekDay(week) {
      const cols = (6 + 12) * 2 // 一行有这么多个格子
      // 点击的是第几行（星期几）？
      const row = [
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
        '星期日',
      ].indexOf(week)
      this.selectWeek([row, row], [0, cols-1], false)
    },
    cellEnter(item) {
      const ele = document.querySelector(
        `td[data-week='${item.row}'][data-time='${item.col}']`
      )
      if (ele && !this.mode) {
        this.left = ele.offsetLeft
        this.top = ele.offsetTop
      } else if (item.col <= this.col && item.row <= this.row) {
        this.width = (this.col - item.col + 1) * ele.offsetWidth
        this.height = (this.row - item.row + 1) * ele.offsetHeight
        this.left = ele.offsetLeft
        this.top = ele.offsetTop
      } else if (item.col >= this.col && item.row >= this.row) {
        this.width = (item.col - this.col + 1) * ele.offsetWidth
        this.height = (item.row - this.row + 1) * ele.offsetHeight
        if (item.col > this.col && item.row === this.row)
          this.top = ele.offsetTop
        if (item.col === this.col && item.row > this.row)
          this.left = ele.offsetLeft
      } else if (item.col > this.col && item.row < this.row) {
        this.width = (item.col - this.col + 1) * ele.offsetWidth
        this.height = (this.row - item.row + 1) * ele.offsetHeight
        this.top = ele.offsetTop
      } else if (item.col < this.col && item.row > this.row) {
        this.width = (this.col - item.col + 1) * ele.offsetWidth
        this.height = (item.row - this.row + 1) * ele.offsetHeight
        this.left = ele.offsetLeft
      }
    },
    cellDown(item) {
      const ele = document.querySelector(
        `td[data-week='${item.row}'][data-time='${item.col}']`
      )
      this.check = Boolean(item.check)
      this.mode = 1
      if (ele) {
        this.width = ele.offsetWidth
        this.height = ele.offsetHeight
      }

      this.row = item.row
      this.col = item.col
    },
    cellUp(item) {
      if (item.col <= this.col && item.row <= this.row) {
        this.selectWeek([item.row, this.row], [item.col, this.col], !this.check)
      } else if (item.col >= this.col && item.row >= this.row) {
        this.selectWeek([this.row, item.row], [this.col, item.col], !this.check)
      } else if (item.col > this.col && item.row < this.row) {
        this.selectWeek([item.row, this.row], [this.col, item.col], !this.check)
      } else if (item.col < this.col && item.row > this.row) {
        this.selectWeek([this.row, item.row], [item.col, this.col], !this.check)
      }

      this.width = 0
      this.height = 0
      this.mode = 0
    },
    selectWeek(row, col, check) {
      // 都是从0开始的
      const [minRow, maxRow] = row
      const [minCol, maxCol] = col
      this.data.forEach((item) => {
        item.child.forEach((t) => {
          if (
            t.row >= minRow &&
            t.row <= maxRow &&
            t.col >= minCol &&
            t.col <= maxCol
          ) {
            this.$set(t, 'check', check)
          }
        })
      })
      // console.log(this.selectState, this.selectValue)
      // this.value.mult_timeRange = JSON.parse(JSON.stringify(this.selectValue))
    },
  },
}
</script>

<style lang="scss" scoped>
$border-color: #dcdfe6;

.c-weektime {
  min-width: 640px;
  /*width: 100%;*/
  position: relative;
  display: inline-block;
}

.c-weektime-table-wrapper {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid $border-color;
  box-sizing: content-box;
}

.c-schedue {
  background: $primary;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0.6;
  pointer-events: none;
}
.c-schedue-notransi {
  transition: width 0.12s ease, height 0.12s ease, top 0.12s ease,
    left 0.12s ease;
}
.c-weektime-table {
  border-collapse: collapse;
  width: 100%;
  min-width: max-content;

  thead tr {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    th {
      line-height: 42px;
      color: #303133;
    }
  }

  tbody tr:last-child td {
    border-bottom: 1px solid transparent;
  }

  tbody td:first-child {
    color: #606266;
  }

  th {
    vertical-align: inherit;
    font-weight: bold;
  }
  tr {
    height: 30px;

    th:first-child,
    td:first-child {
      border-left: 1px solid transparent;
    }

    &:first-child th {
      border-top: 1px solid transparent;
    }

    &:last-child td.weektime-atom-item {
    }

    th:last-child,
    td:last-child {
      border-right: 1px solid transparent;
    }
  }
  tr,
  td,
  th {
    user-select: none;
    border: 1px solid $border-color;
    text-align: center;
    width: 20px;
    /*line-height: 1.8em;*/
    line-height: normal;
    transition: background 0.2s ease;
    box-sizing: content-box;
    height: 24px;
  }

  .c-weektime-head {
    font-size: 12px;
    .week-td {
      width: 70px;
    }
  }
  .c-weektime-body {
    font-size: 12px;
    td {
      &.weektime-atom-item {
        user-select: unset;
        /*background-color: #f5f5f5;*/
      }
      &:not(.weektime-atom-item) {
        width: 64px;
      }
      &.ui-selected {
        background-color: $primary;
      }
    }
  }
  .c-weektime-preview {
    line-height: 2.4em;
    padding: 0 10px;
    font-size: 14px;
    .c-weektime-con {
      line-height: 46px;
      user-select: none;
    }
    .c-weektime-time {
      text-align: left;
      line-height: 2.4em;
      p {
        max-width: 625px;
        line-height: 1.4em;
        word-break: break-all;
        margin-bottom: 8px;
      }
    }
  }
}
.c-min-table {
  tr,
  td,
  th {
    min-width: 24px;
  }
}
.g-clearfix {
  &:after,
  &:before {
    clear: both;
    content: ' ';
    display: table;
  }
}
.g-pull-left {
  float: left;
  color: #606266;
}
.g-pull-right {
  float: right;
  color: #c0c4cc;
}
.g-tip-text {
  color: #999;
}

.preview-time {
  min-height: 48px;
  border-radius: 4px;
  background: #f5f7fa;
  padding: 13px 16px;
  line-height: normal;
  align-items: flex-start;

  .el-button {
    padding: 0.2em;
  }

  p {
    color: #606266;
    font-size: 14px;
  }

  & + .preview-time {
    margin-top: 8px;
  }
}

.danger {
  color: $danger;
}
</style>
