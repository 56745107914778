<template>
  <el-form
    v-if="form"
    ref="FormOne"
    class="medium-form"
    size="medium"
    :model="form"
    :rules="rules"
    label-width="135px"
  >

    <!--    label-width="40%"-->
    <!--    {{ form }}-->
    <el-form-item
      label="场地名称："
      prop="name"
      :rules="[{ message: '请输入场地名称', trigger: 'blur', required: true }]"
    >
      <el-input v-model="form.name" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item
      :label="tenantVersion === 'school' ? '所属校友会：' : '所属组织：'"
      prop="organization_id"
      :rules="[
        {
          message: '请选择所属组织',
          required: true,
          trigger: 'change',
        },
      ]"
    >
      <el-cascader
        v-if="positionOptions"
        v-model="form.organization_id"
        :options="isDisabled(positionOptions)"
        popper-class="organization-id-cascader"
        :props="props"
        @change="handleExportCatalogChange"
        ref="CascaderRef"
        filterable
      >
      </el-cascader>
    </el-form-item>
    <el-form-item label="场地分类：" prop="category_id">
      <el-select v-model="form.category_id">
        <el-option
          v-for="c in categoryOptions"
          :key="c.id"
          :label="c.name"
          :value="c.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="场地地址：" prop="address">
      <venue-address v-model="form.address"></venue-address>
    </el-form-item>
    <el-form-item label="容纳人数：">
      <el-input-number
        v-model="form.people_number"
        :min="0"
        label="不限"
      ></el-input-number>
      <!--      <p class="info mTop"><i class="icon el-icon-info" /></p>-->
    </el-form-item>
    <el-form-item v-if="form.category_id" label="场地设施：">
      <el-checkbox-group v-model="form.venue_facility_ids">
        <el-checkbox
          v-for="item in facilities"
          :label="item.id"
          :key="item.id"
          :disabled="!form.category_id"
          >{{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
      <span v-if="facilities.length === 0">暂无设施</span>
    </el-form-item>
    <!--    <el-form-item prop="image" label="场地图片：">-->
    <!--      <image-wall v-model="productForm.image" :width="96" :height="96" addText="上传图片">-->
    <!--        <p slot="info" class="info-title">推荐尺寸 96*96</p>-->
    <!--      </image-wall>-->
    <!--    </el-form-item>-->
    <el-form-item
      label="场地设置使用文档："
      prop="files"
      style="max-width: 480px"
    >
      <el-upload
        action="#"
        :before-remove="beforeRemove"
        :on-remove="handleRemove"
        :http-request="handleUpload"
        :before-upload="beforeUpload"
        :multiple="false"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip" style="line-height: normal">
          只能上传word、excel、pdf、jpg、png文件，且不超过150M
        </div>
      </el-upload>
    </el-form-item>
    <el-form-item label="场地图片：" prop="images" style="max-width: 600px">
      <media-wall
        v-model="form.images"
        :width="135"
        :height="135"
        :limit="form.images ? 9 - form.images.length : 1"
        UploadType="image"
        :draggable="true"
        :border-radius="4"
        add-text=""
      >
        <template #info> </template>
      </media-wall>
      <el-input v-if="form.images" v-model="form.images.join(',')" v-show="false" />
      <p class="form-tip info">
        <i class="el-icon-info" style="font-size: 15px; color: #c0c4cc"></i
        >拖动图片可以对其排序
      </p>
    </el-form-item>
    <el-form-item label="备注：">
      <!--
              show-word-limit
              :maxlength="100"
      -->
      <el-input
        clearable
        type="textarea"
        placeholder="请输入"
        v-model="form.remark"
        :rows="2"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { getAllFacilities, getVenueCategory, ParentList } from '../../api'
import VenueAddress from './VenueAddress'
import MediaWall from '../../../common/components/MediaWall'
import { getFileType } from '../../../../base/utils/tool'
import filesUpload from '../../../../base/utils/upload4'

export default {
  components: { MediaWall, VenueAddress },
  inject: ['getForm'],
  data() {
    return {
      positionOptions: null,
      categoryOptions: [],
      facilities: [],
      props: {
        checkStrictly: true,
        expandTrigger: 'hover',
        label: 'name',
        value: 'id',
        emitPath: false,
      },
      rules: {}
    }
  },
  computed: {
    form() {
      return this.getForm()
    },
    tenantVersion() {
      return this.$store.getters.tenant?.version
    },
    fileList: {
      get() {
        return this.form && this.form.facility_file_url
          ? [
              {
                name: this.form.facility_file_name || '场地设置使用文档',
                url: this.form.facility_file_url,
              },
            ]
          : []
      },
      set(val) {
        if (val && val.length) this.form.facility_file_url = val[0]
      },
    },
  },
  watch: {
    'form.category_id': {
      handler(newValue, oldValue) {
        if (newValue) {
          getAllFacilities({ category_id: newValue })
            .then(({ data }) => {
              this.facilities = data
              if (oldValue) this.form.venue_facility_ids = []
            })
            .catch(() => {})
        }
      },
      immediate: true,
    },
  },
  created() {
    // console.log(this.form)
    this.getPositionOptions()
    getVenueCategory()
      .then(({ data, msg }) => {
        this.categoryOptions = data
      })
      .catch(() => {})
  },
  methods: {
    isDisabled (list){
      return list.map(item=>{
      return {...item,disabled:item.disabled ===1,children:item.children?this.isDisabled(item.children):null} 
      })
    },
    // 关闭架构折叠面板
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    // 获取所属架构列表
    getPositionOptions() {
      ParentList({ is_show_none: 0, is_all: 1, hashid_enable: 1 }).then(
        (res) => {
          const { data } = res
          this.positionOptions = data
        }
      )
    },
    beforeRemove(file) {
      /* 不符合条件的文件无法上传，选择后会触发 beforeRemove 和 handleRemove */
      if (!this.beforeUpload(file, true)) return
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      // this.$set(this.form, 'files', [])
    },
    /**
     * @param file
     * @param silent  是否关闭错误提示
     * */
    beforeUpload(file, silent = false) {
      /* 文件类型判断 */
      const myFileType = getFileType(file?.name)
      const fileTypeSupported = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'jpg',
        'jpeg',
        'png',
      ].includes(myFileType)
      // 大小判断
      const is10M = file.size / 1024 / 1024 < 150
      if (!fileTypeSupported && !silent) {
        this.$message.error('上传文件类型仅支持 word、excel、pdf、jpg、png !')
      } else if (!is10M && !silent) {
        // 大小不匹配
        this.$message.error('上传文件大小不能超过 150MB !')
      }
      // 返回 false 阻断 true 正常上传
      return is10M && fileTypeSupported
    },
    handleUpload(file) {
      /* 上传成功后才将{name: '', url: ''}push到fileList中，会有成功图标 */
      // this.fileList.push({name: file.file.name, url: ''})
      filesUpload({
        configApi: '/admin/admin/media/requestUpload',
        data: file.file,
        progress: (n) => {
          // 更新 el-upload file-list 自带的进度条数值
          file.onProgress({ percent: n })
        },
      })
        .then((res) => {
          // this.fileList.push({ ...res.data, name: file.file.name })
          this.form.facility_file_url = res.data.url
          this.form.facility_file_name = file.file.name
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.medium-form .el-textarea {
  min-height: 94px;
  height: auto;

  textarea {
    resize: vertical;
    min-height: inherit !important;
  }
}
</style>