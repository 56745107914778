<template>
  <div>
    <div class="step-box">
      <!-- 操作步骤条 -->
      <VenueStep
        v-model="stepsStatus"
        :type="$route.params.id ? '' : 'add'"
        @clickStatus="clickStepChange"
        :isDisabled="isStepClickDisabled"
      ></VenueStep>
    </div>
    <!-- 流程第一部分-->
    <div class="module" v-if="stepsStatus === 1">
      <One ref="one"></One>
    </div>
    <div class="module" v-if="stepsStatus === 2">
      <Two ref="two"></Two>
    </div>
    <div class="module" v-if="stepsStatus === 3">
      <Three ref="three"></Three>
    </div>
    <fixed-action-bar>
      <el-button @click="cancel">取消</el-button>
      <el-button
        v-if="stepsStatus < 3 && isStepClickDisabled"
        type="primary"
        :loading="loading"
        @click="nextStep"
        >下一步</el-button
      >
      <el-button v-else type="primary" :loading="loading" @click="nextStep">{{
        isStepClickDisabled ? '完成' : '保存'
      }}</el-button>
    </fixed-action-bar>
  </div>
</template>
<script>
import weektimeData from '../data/weektime_data.js'

import VenueStep from '../components/add/VenueStep'
import One from '../components/add/one'
import {
  getVenueInfo,
  getVenueReserveInfo,
  getVenueSettings,
  saveVenueInfo,
  saveVenueReserveInfo,
  saveVenueSettings,
  checkUserClientInfo,
} from '../api'
import FixedActionBar from '../../../base/layout/FixedActionBar'
import Two from '../components/add/two'
import Three from '../components/add/three'
import { parseToStamp, parseToTime } from '../js/time-tool'
export default {
  components: { Three, Two, FixedActionBar, VenueStep, One },
  provide() {
    return {
      getForm: () => this.detailForm,
    }
  },
  data() {
    return {
      stepsStatus: 1,
      // 步骤条点击是否禁用 | 也表示未填写完毕资料
      // isStepClickDisabled: !this.$route.params.id,
      detailForm: null,
      loading: false,
      // create_step: 1, // 0 表示创建步骤都已完成
      addId: '', // 新增id
    }
  },
  computed: {
    // 新增场地或者场地步骤未完成，则不允许随意切换步骤
    isStepClickDisabled() {
      return this.$route.params.step != 0 || !this.$route.params.id
    },
    venueId() {
      return this.$route.params.id || this.addId
    },
  },
  created() {
    if (this.$route.params.id) {
      this.stepsStatus = this.isStepClickDisabled
        ? Number(this.$route.params.step)
        : 1
      this.clickStepChange(this.stepsStatus)
    } else {
      this.detailForm = {
        id: 0,
        name: '',
        organization_id: '',
        address: {
          lat: '',
          lng: '',
          name: '',
          address: '',
        },
        people_number: 0,
        images: [],
        remark: '',
        venue_facility_ids: [],
      }
    }
    checkUserClientInfo().then((res) => {
      if (!res.data.is_exist) {
        this.$confirm(res.msg, '提示', {
          type: 'warning',
        })
          .then(() => {})
          .catch(() => {})
      }
    })
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    nextStep() {
      this.loading = true
      switch (this.stepsStatus) {
        case 1:
          this.$refs.one.$refs.FormOne.validate((v) => {
            if (v) {
              this.saveStepOne()
            } else this.loading = false
          })
          break
        case 2:
          this.$refs.two?.$refs.FormTwo?.validate((v) => {
            if (v) {
              this.saveSettings()
            } else this.loading = false
          })
          break
        case 3:
          this.$refs.three?.$refs?.FormThree?.validate((v) => {
            if (v) {
              this.saveStepThree()
            } else this.loading = false
          })
          break
        default:
      }
    },
    saveStepOne() {
      saveVenueInfo({
        ...this.detailForm,
        address: this.detailForm.address.lat ? this.detailForm.address : null,
      })
        .then(({ data, msg }) => {
          this.addId = data.id
          this.$message.success(msg)
          if (this.isStepClickDisabled)
            this.clickStepChange(this.stepsStatus + 1)
          else this.getBaseInfo()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    saveStepThree() {
      saveVenueReserveInfo({
        ...this.detailForm,
      })
        .then(({ msg }) => {
          this.$message.success(msg)
          this.cancel()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 点击步骤条状态改变，或者刷新
    clickStepChange(current) {
      // 改变步骤条
      this.stepsStatus = current
      // 判断步骤执行哪一步
      switch (current) {
        case 1:
          // 基本信息
          this.getBaseInfo()
          break
        case 2:
          this.getSettings()
          break
        case 3:
          this.getReserveInfo()
      }
    },
    getBaseInfo() {
      getVenueInfo(this.$route.params.id)
        .then(({ data }) => {
          data.address = data.address || {
            lat: '',
            lng: '',
            name: '',
            address: '',
          }
          this.detailForm = data
        })
        .catch(() => {})
    },
    getReserveInfo() {
      getVenueReserveInfo(this.venueId)
        .then(({ data }) => {
          this.detailForm = data
        })
        .catch(() => {})
    },
    getSettings() {
      getVenueSettings(this.venueId)
        .then(({ data, msg }) => {
          data.reserve_time_scope = data.reserve_time_scope || '12month'
          // todo: parse reserve_times
          data.reserve_times = data.reserve_times || []
          data.special_times = data.special_times || []

          data.reserve_times.forEach((rt) => {
            const matcher = [
              '星期日',
              '星期一',
              '星期二',
              '星期三',
              '星期四',
              '星期五',
              '星期六',
            ]

            // like: {day: 0, end: 68400, begin: 25200}[]
            const w_data = rt.weektimeData

            const dragData = JSON.parse(JSON.stringify(weektimeData))

            dragData.forEach((wd) => {
              w_data.forEach((dt) => {
                console.log(wd.value)
                if (dt.day === matcher.indexOf(wd.value)) {
                  wd.check = true
                  wd.child.forEach((ch) => {
                    // console.log([
                    //   parseToStamp('07:00'),
                    //   parseToStamp('07:30'),
                    //   parseToStamp('08:00'),
                    //   parseToStamp('12:00'),
                    //   parseToStamp('24:00')
                    // ])
                    const td = parseToStamp(ch.begin)

                    if (td >= dt.begin && td < dt.end) {
                      ch.check = true
                    }
                  })
                }
              })
            })

            rt.weektimeData = dragData

            const d_data = rt.daytimeData

            const dragData2 = JSON.parse(
              JSON.stringify(weektimeData.slice(0, 1))
            )

            dragData2.forEach((wd) => {
              d_data.forEach((dt) => {
                wd.check = true
                wd.child.forEach((ch) => {
                  const td = parseToStamp(ch.begin)
                  if (td >= dt.begin && td < dt.end) {
                    ch.check = true
                  }
                })
              })
            })

            rt.daytimeData = dragData2

            rt.inclusions =
              rt.inclusions?.map((ex) => {
                return {
                  ...ex,
                  dateRange: [ex.start_date * 1000, ex.end_date * 1000],
                  // 如：43200 变 '12:00'
                  // start_time: parseToTime(ex.start_time),
                  // end_time: parseToTime(ex.end_time)
                  timeData:
                    ex.timeData?.map((td) => {
                      return {
                        ...td,
                        start_time: parseToTime(td.begin),
                        end_time: parseToTime(td.end),
                      }
                    }) || [],
                }
              }) || []

            if (rt.start_date === 0 && rt.end_date === 0) {
              rt.dateRange = null
            } else rt.dateRange = [rt.start_date * 1000, rt.end_date * 1000]
          })

          data.special_times.forEach((st) => {
            if (st.start_date === 0 && st.end_date === 0) {
              st.dateRange = null
            } else st.dateRange = [st.start_date * 1000, st.end_date * 1000]
          })

          this.detailForm = data
        })
        .catch((err) => {
          console.log('get settings error', err)
        })
    },
    saveSettings() {
      const reserve_times = this.detailForm.reserve_times.map((r) => {
        // 剔除不必要的字段
        const { dateRange, mult_timeRange, mult_timeRange2, ...others } = r

        const inclusions = r.inclusions.map((ex) => {
          const { dateRange, timeRange, ...otherEx } = ex

          const timeData = ex.timeData.map((td) => {
            return {
              ...td,
              begin: td.start_time ? parseToStamp(td.start_time) : 0,
              end: td.end_time ? parseToStamp(td.end_time) : 0,
            }
          })

          return {
            ...otherEx,
            start_date:
              ex.dateRange && ex.dateRange.length ? ex.dateRange[0] / 1000 : 0,
            end_date:
              ex.dateRange && ex.dateRange.length ? ex.dateRange[1] / 1000 : 0,
            // start_time: ex.start_time ? parseToStamp(ex.start_time) : 0,
            // end_time: ex.end_time ? parseToStamp(ex.end_time) : 0,
            timeData,
          }
        })

        return {
          //  todo
          ...others,
          start_date:
            r.dateRange && r.dateRange.length ? r.dateRange[0] / 1000 : 0,
          end_date:
            r.dateRange && r.dateRange.length ? r.dateRange[1] / 1000 : 0,
          weektimeData: r.mult_timeRange,
          daytimeData: r.mult_timeRange2,
          inclusions,
        }
      })

      const special_times = this.detailForm.special_times.map((r) => {
        // 剔除不必要的字段
        const { dateRange, ...others } = r
        return {
          ...others,
          start_date:
            r.dateRange && r.dateRange.length ? r.dateRange[0] / 1000 : 0,
          end_date:
            r.dateRange && r.dateRange.length ? r.dateRange[1] / 1000 : 0,
        }
      })
      console.log({
        ...this.detailForm,
        reservable_user_ids: this.detailForm.reservable_users?.length
          ? this.detailForm.reservable_users.map((el) => el.user_id)
          : [],
        reserve_times,
        special_times,
      })
      // test only next two lines
      // this.loading = false
      // return
      saveVenueSettings({
        ...this.detailForm,
        reservable_user_ids: this.detailForm.reservable_users?.length
          ? this.detailForm.reservable_users.map((el) => el.user_id)
          : [],
        reserve_times,
        special_times,
      })
        .then(({ msg }) => {
          this.$message.success(msg)
          if (this.isStepClickDisabled)
            this.clickStepChange(this.stepsStatus + 1)
          else this.getSettings()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.module {
  margin-top: 40px;
}
</style>
